import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const CommunityImpactPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Community Impact</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
                    <h2>Impact Report</h2>
					<p>An impact report is a document that summarizes the outcomes of an organization's work. It highlights the positive changes that have occurred due to the organization's programs and initiatives. Humanity Northwoods Wisconsin's Impact Report is below.</p>
                    <p><a href="/documents/2024-Impact-Report.pdf" target="_blank" class="button">View Impact Report</a></p>
					<div className="spacer"></div>
                    <h2>990</h2>
                    <p>A 990 includes an organization's mission statement, financial data, a summary of activities, specifics of a nonprofit's governing body, and accomplishments from the prior year.  Habitat for Humanity Northwoods Wisconsin's 990 for the past year is attached below.</p>
                    {/* <p><a href="" target="_blank" class="button">View 990</a></p> */}
                </main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default CommunityImpactPage

export const Head = () => (
	<Seo
        title="Community Impact | Habitat For Humanity Northwoods Wisconsin"
    />
)
